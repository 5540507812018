.login_button_testcolor {
	color: white;
}

.login_input {
	height: 5vh;
	border-radius: 5px;
	border: 1px solid black;
}

/* --------------------------------------------------------------------- */

/* STYLES USED FOR CUSTOM DROPDOWN COMPONENT */

.dropdown__wrapper {
	z-index: 4;
	position: relative;
}

.dropdown_icon {
	font-size: 25px;
	color: "grey";
	cursor: pointer;
}

.dropdown_tooltip {
	display: none;
	z-index: 5;
	top: 110%;
	position: absolute;
	background-color: white;
	border: 1px solid #a09d9d;
	color: #a09d9d;
	font-size: 10px;
	padding: 2px;
}

.dropdown_icon:hover {
	color: #176b87;
}

.dropdown_icon:hover ~ .dropdown_tooltip {
	display: block;
}

.filter__dropdown {
	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.15);
	position: absolute;
	top: 200%;
	right: -20%;
	padding: 1rem;
	width: max-content;
	height: auto;
	box-shadow: 0px 0px 0.7px grey;
}

.filter__dropdown.active {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
	transition: 200ms ease;
}

.filter__dropdown.inactive {
	opacity: 0;
	visibility: hidden;
	transform: translateY(-20px);
	transition: 200ms ease;
}

.filter__dropdown > div {
	padding: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
}

/* --------------------------------------------------------------------- */

/* UTILITY STYLES */

.flex__centered {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex__centered5 {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
}
/* --------------------------------------------------------------------- */

/* DASHBOARD STYLES */

.card_height {
	height: 70%;
}

/* --------------------------------------------------------------------- */

/* STYLES USED FOR OVERWRITING DATEPICKER STYLES */

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
	background: #176b87 !important;
	color: white !important;
}

.react-datepicker__day--disabled {
	color: #c7c7c7 !important;
}

.datepicker__wrapper {
	z-index: 4;
	display: flex;
	flex-direction: column;
}

.datepicker__wrapper > span {
	font-size: 10px;
}

.datepicker__overwrite {
	border-radius: 4px;
	border: 1px solid #d3d3d3;
	height: 34px;
}

.datepicker__overwrite:focus {
	border: 1px solid #176b87;
}

.small_width {
	width: 150px;
}

/* --------------------------------------------------------------------- */

/* STYLES USED FOR TABLE LAYOUT */

.layout__wrapper__sb {
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
}

.inner__wrapper_flexgap {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
}

.inner__wrapper_columnflexgap {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
}

.reload__button {
	font-size: 18px;
	background-color: transparent;
	border: 0;
}

.reload__button:focus {
	outline: 0;
}

.date__badge {
	width: 100%;
	justify-content: flex-start;
}

.date__badge > span {
	border: 1px solid #176b87;
	padding: 2px;
	font-size: 8px;
	border-radius: 2px;
	color: #176b87;
	cursor: pointer;
}

.table__wrapper {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	width: 100%;
}

.table__inner__wrapper {
	width: 100%;
	position: relative;
	overflow: auto;
	scroll-behavior: smooth;
	white-space: nowrap;
	max-height: 470px;
}

.text_right {
	text-align: right;
}

.text_center {
	text-align: center;
}

.master_checkbox {
	width: 20px;
	height: 20px;
	accent-color: #176b87;
	cursor: pointer;
}

.arrow-button {
	background-color: transparent;
	border: 0;
	font-size: 15px;
	color: #a09d9d;
}

.arrow-button:focus {
	color: #176b87;
	background-color: #f3f3f3;
	outline: 0;
	border-radius: 40%;
}

.active-arrow {
	color: black;
}

.rotated {
	transform: rotate(180deg);
}

.table_checkbox_wrapper > input[type="checkbox"] {
	accent-color: #176b87;
	cursor: pointer;
	width: 17px;
	height: 17px;
}

.table__inner__wrapper table {
	width: 100%;
	border-collapse: collapse;
	position: relative;
}

.table__inner__wrapper th {
	position: sticky;
	top: 0;
	background-color: white;
}

.table__inner__wrapper td {
	height: auto;
}

.sticky_col {
	position: -webkit-sticky;
	position: sticky;
	background-color: white;
}

.sticky_col_selected {
	background-color: #ebf3fe;
}

.table__inner__wrapper th.sticky_col {
	z-index: 2;
}

.first_col {
	width: 60px;
	min-width: 60px;
	max-width: 60px;
	left: 0;
}

.second_col {
	width: 60px;
	min-width: 60px;
	max-width: 60px;
	left: 60px;
}

.third_col {
	width: 150px;
	min-width: 150px;
	max-width: 150px;
	left: 120px;
}

.wrapped_content {
	width: 250px;
	white-space: normal;
}

.drop {
	display: none;
	margin-left: 0;
}

.plain {
	display: flex;
	margin-left: 0.5rem;
}

@media (max-width: 768px) {
	.drop {
		display: flex;
	}

	.plain {
		display: none;
	}
}

/* --------------------------------------------------------------------- */

/* STYLES USED FOR PAGINATION */

.custom_pagination {
	padding-left: 0;
	list-style: none;
	border-radius: 2px;
	display: flex;
	justify-content: center;
	border: 1px solid #ccc;
}

.custom_pagination button {
	border: 0;
	padding: 6px 10px;
	border-right: 1px solid #ccc;
	border-left: 1px solid transparent;
	border-radius: 0px;
	background-color: #fff;
	color: #176b87;
	text-decoration: none;
}

.custom_pagination button:focus {
	outline: 0;
}

.custom_pagination button:disabled {
	background-color: #ebeaea;
	opacity: 0.6;
	cursor: not-allowed;
}

.custom_pagination button:hover {
	color: #ffffff;
	background-color: #176b87;
	border-left-color: #ccc;
	border-right-color: #ccc;
}

.custom_pagination button.active_page {
	color: #ffffff;
	background-color: #176b87;
	border-color: #176b87;
}

/* --------------------------------------------------------------------- */

/* ************** ACL STYLINGS *************** */

.centeringdiv {
	display: flex;
	justify-content: center;
	align-items: center;
}

.aclcreateform {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin-top: 5vh;
}

.aclcreateform > div {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 10px;
	gap: 2rem;
}

/* ************** COMMON BUTTON STYLINGS *************** */

.yesInDelete {
	height: 30px !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	margin-left: 10px;
	background-color: #0071bc !important;
	border: none !important;
}

.noInDelete {
	height: 30px !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	margin-left: 10px;
	background-color: #dc3545 !important;
	border: none !important;
}

.onMouseOver:hover {
	cursor: pointer;
	/* color: #176b87; */
	scale: 1.2;
}

/* ************** TOGGLE BUTTON STYLINGS *************** */

.toggle-button {
	background-color: grey;
	border-radius: 20px;
	cursor: pointer;
	height: 30px;
	overflow: hidden;
	position: relative;
	width: 50px;
}

.toggle-button.on {
	background-color: #176b87;
}

.inner-circle {
	width: 20px;
	height: 20px;
	background-color: #fff;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	transform: translate(0%, -50%);
	transition: transform 0.3s, background-color 0.3s;
}

.toggle-button.on .inner-circle {
	transform: translate(130%, -50%);
}

/* --------------------------------------------------------------------- */

/* STYLES USED FOR SIDEBAR */

.active_bg {
	background-color: #ebf3fe;
}

/* --------------------------------------------------------------------- */
